import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, MenuItem } from "@mui/material";

import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomDialog from "lib/components/Modals/CustomModal";
import {
  addTrip,
  getAllDrivers,
  getAllFleets,
  getAllDestinations,
} from "api/API";

const types = [
  {
    value: "TUR",
    label: "Tur",
  },
  {
    value: "RETUR",
    label: "Retur",
  },
];

const AddTripModal = ({
  open = false,
  setOpen = () => {},
  addCallback = () => {},
  routeType = "",
}) => {
  const [drivers, setDrivers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [addTripData, setAddTripData] = useState({
    startDeparture: null,
    finalDeparture: null,
    type: "",
    driverId: "",
    carId: "",
    sits: "",
    destinationId: "",
    price: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    getAllDrivers().then((res) => setDrivers(res));
    getAllFleets().then((res) => setFleet(res));
    getAllDestinations().then((res) => setDestinations(res));
  }, []);

  const handleChange = (e) => {
    setAddTripData({
      ...addTripData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitTrip = () => {
    if (addTripData) {
      const newTripData = { ...addTripData };
      newTripData.type = routeType;
      newTripData.price = parseInt(newTripData.price);
      newTripData.sits = parseInt(newTripData.sits);

      addTrip(newTripData, () => {
        setAddTripData("");
        addCallback();
      });
    }
  };

  return (
    <div>
      {" "}
      <CustomDialog
        handleClose={() => setOpen(false)}
        open={open}
        title={t("Add trip")}
        button1={t("Finalize")}
        button2={t("Quit")}
        onClickButton1={() => handleSubmitTrip()}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            spacing={2}
            columns={{ xs: 4, md: 12 }}
          >
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Start Departure Period")}
                  name="startDeparture"
                  value={addTripData.startDeparture}
                  onChange={(value) => {
                    handleChange({ target: { name: "startDeparture", value } });
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                        width: "16rem",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("End Departure Period")}
                  name="finalDeparture"
                  value={addTripData.finalDeparture}
                  onChange={(value) => {
                    handleChange({ target: { name: "finalDeparture", value } });
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                        width: "16rem",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={t("Driver")}
                name="driverId"
                onChange={(e) => {
                  /**
                   * Automatically chose driver's default car [Arci :*]
                   */
                  const driverId = e.target.value;
                  const carId =
                    drivers.filter((driver) => driver.id === driverId)[0]
                      ?.carId || "";

                  setAddTripData({
                    ...addTripData,
                    driverId,
                    carId,
                  });
                }}
                value={addTripData?.driverId}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                {drivers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.lastName + " " + option.firstName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={t("Car")}
                name="carId"
                onChange={handleChange}
                value={addTripData.carId}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                {fleet.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.registrationPlate}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  required
                  id="outlined-required"
                  label={t("Nr. of seats")}
                  name="sits"
                  value={addTripData?.sits}
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={t("Departure Place")}
                name="departurePlace"
                onChange={handleChange}
                value={addTripData?.departurePlace}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                {destinations.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.city.name +
                      ", " +
                      option.country.name +
                      ", " +
                      option.states.name +
                      " --> " +
                      option.location}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={t("Destination")}
                name="destinationId"
                onChange={handleChange}
                value={addTripData?.destinationId}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                {destinations.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.city.name +
                      ", " +
                      option.country.name +
                      ", " +
                      option.states.name +
                      " --> " +
                      option.location}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                required
                id="outlined-required"
                label={t("Ticket price")}
                name="price"
                onChange={handleChange}
                value={addTripData?.price}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </div>
  );
};

export default AddTripModal;
