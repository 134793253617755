const translation = {
  Documents: "Documente",
  "Add document": "Adaugă document",
  "File has been uploaded!": "Fișierul a fost încărcat!",
  "You didn't upload any file!": "Nu ați încărcat nici un fișier!",
  Specialization: "Specializare",
  "Additional documents": "Documente adiționale",
  "Search the file": "Caută documentul",
  "in your computer": "în calculator",
  "Select document from Google": "Selectati documentul din Google Drive",
  "Your role": "Rolul tău",
  "Generating PDF, it takes about 10 seconds.":
    "Generare PDF, durează aproximativ 10 secunde",
  Logout: "Deloghează-te",
  "Are you sure you want to log out?":
    "Sunteți sigur că doriți să vă deconectați?",
  BACK: "ÎNAPOI",
  SEND: "TRIMITE",
  "Bugs / questions sent successfully!": "Erori / întrebări trimise cu succes!",
  "Description / Question": "Descriere / Întrebare",
  "Bugs / questions coundn't be sent successfully!":
    "Erori / întrebări nu au putut fi trimise cu succes!",
  "Get help": "Obține ajutor",
  "* When describing a bug, you must present it in as much detail as possible, describe the steps do within the platform until you discover your bug and other relevant details!":
    "* Când descrieți un bug, trebuie să îl prezentați cât mai detaliat posibil, să descrieți pașii pe care îi faceți în cadrul platformei până când vă descoperiți bug-ul și alte detalii relevante!",

  /**
   * -------------------------------------------------------------------------------
   * - [Tasks]
   * -------------------------------------------------------------------------------
   */
  Tasks: "Task-uri",
  "Task details": "Detalii task",
  "Add task": "Adaugă task",
  "Edit task": "Editează task",
  "Are you sure you want to delete this task?":
    "Ești sigur că vrei să ștergi acest task?",
  "Task has been added!": "Task-ul a fost adaugat!",
  "Task has been modified!": "Task-ul a fost modificat!",
  "Task has been deleted!": "Task-ul a fost șters!",
  "To Do": "De făcut",
  Done: "Făcut",
  "Task has been marked as completed!": "Task-ul a fost marcat ca complet!",
  "Task has been marked as uncompleted!": "Task-ul a fost marcat ca incomplet!",
  All: "Toți",
  ToDo: "De făcut",
  InProgress: "În progres",
  "Are you sure you want to update the status? This cant be undone!":
    "Ești sigur că vrei să schimbi statusul? Acest lucru nu poate fi anulat!",
  "Title should not be empty": "Câmpul titlu nu trebuie să fie gol!",
  "Description should not be empty": "Câmpul descriere nu trebuie să fie gol!",
  "UserId should not be empty": "Câmpul responsabil nu trebuie să fie gol!",
  "FirstName should not be empty": "Câmpul prenume nu trebuie să fie gol!",
  "FirstName must be a string": "Câmpul prenume trebuie să conțină litere",
  "LastName should not be empty": "Câmpul nume nu trebuie să fie gol!",
  "LastName must be a string": "Câmpul nume trebuie să conțină litere",
  "Phone should not be empty": "Câmpul telefon nu trebuie să fie gol",
  "Phone must be a phone number":
    "Câmpul telefon trebuie să conțină un număr de telefon",
  "CNP should not be empty": "Câmpul CNP nu trebuie să fie gol",
  "CNP must be a number string": "Câmpul CNP trebuie să conțină cifre",
  "Series should not be empty": "Câmpul serie de buletin nu trebuie să fie gol",
  "ICNumber should not be empty":
    "Câmpul număr de buletin nu trebuie să fie gol",
  "Birthday should not be empty":
    "Câmpul dată de naștere nu trebuie să fie gol",
  "Gender should not be empty": "Alegeți unul dintre sexe",
  "days left": "zile rămase",
  "days ago": "zile în urmă",

  /**
   * -------------------------------------------------------------------------------
   * - [Periods]
   * -------------------------------------------------------------------------------
   */
  Periods: "Perioade",
  "All periods": "Toate perioadele",
  "Period has been added": "Perioada de timp a fost adaugată",
  "Add period": "Adaugă perioadă",
  Period: "Perioadă",
  "Period*": "Perioadă*",
  "Time should not be empty": "Câmpul cu perioada nu trebuie să fie gol",
  "Are you sure you want to delete this period?":
    "Sunteți sigur că doriți să ștergeți această perioadă?",
  "Period has been deleted!": "Perioada a fost ștearsă cu succes",

  /**
   * -------------------------------------------------------------------------------
   * - [Dashboard]
   * -------------------------------------------------------------------------------
   */
  "Finished trips": "Curse finalizate",
  "Fields distribution": "Distributie pe culturi",
  "Actual registrations": "Înscrieri actuale",
  "Transport revenue": "Venituri transport",
  "Filter by money": "Filtrare după bani incasați",
  "Filter by registrations": "Filtrare după înscrieri",
  "Days ": "Zile",
  Months: "Luni",
  Apply: "Aplică",
  "Delete filters": "Șterge filtre",
  "Choose dates": "Alege date",
  "Welcome to the Lucicosm Mediere platform":
    "Bine ai venit pe platforma Lucicosm Mediere",
  Hello: "Bună",
  /**
   * -------------------------------------------------------------------------------
   * - [Client details]
   * -------------------------------------------------------------------------------
   */
  Clients: "Clienți",
  "See client": "Vezi client",
  "Client has no documents.": "Nu există documente pe client.",
  "Client details": "Detalii client",
  "All clients": "Toți clienții",
  "Add client": "Adaugă client",
  "Visualize clients": "Vizualizare clienți",
  "Are you sure you want to block client":
    "Ești sigur că vrei să blochezi clientul",
  "Client has been blocked!": "Clientul a fost blocat!",
  "Are you sure you want to unblock client":
    "Ești sigur că vrei să deblochezi clientul",
  "Client has been unblocked!": "Clientul a fost deblocat!",
  "Are you sure you want to delete this departure?":
    "Ești sigur că vrei să ștergi această deplasare?",
  "Client fields": "Culturile clientului",
  "Select new fields from the list": "Alegeti culturi noi din listă",
  "Available time periods": "Perioade disponibile",
  "Please select the dates": "Selectează perioadele",
  Duration: "Număr de zile",
  "Client succesfully edited": "Client editat cu succes",
  "Add new tag": "Atribuie un tag nou",
  "Add new info": "Adaugă informație nouă",
  Series: "Serie buletin",
  Number: "Numar",
  "Contact Person": "Persoană de contact",
  Nationality: "Naționalitate",
  "Add Additional Period": "Prelungire perioadă contractuală",
  "Additional Contract": "Contract adițional",
  "Number of days": "Număr de zile",
  "Custom period": "Perioadă personalizată",
  Registrations: "Înscrieri",
  Gender: "Gen",
  Days: "Număr de zile",
  "Filter after birthday": "Filtrează după data de naștere",
  "Find client": "Caută client",
  "Choose a tag": "Alege un tag",
  "Expiration date CI": "Dată de expirare CI",
  "CiExpireDate should not be empty":
    "Câmpul pentru data de expirare a buletinului nu poate fi gol",
  "Client payments": "Plăți clienți",
  "The client already exists": "Acest client există deja!",
  "Birth Place": "Locul nașterii",

  /**
   * -------------------------------------------------------------------------------
   * - [Tags]
   * -------------------------------------------------------------------------------
   */
  Tags: "Taguri",
  "Add tag": "Adaugă tag",
  "Edit tag": "Editează tag",
  "This client has no tags.": "Nu există taguri pe client.",
  "Tag has been added!": "Tagul a fost adăugat!",
  "Tag has been edited!": "Tagul a fost modificat!",
  "Tag has been deleted!": "Tagul a fost șters!",
  "Are you sure you want to delete this tag?":
    "Ești sigur(ă) că vrei să ștergi acest tag?",
  "Manage tags": "Administrează taguri",
  "Tag name is not valid!": "Numele tag-ului nu este valid",
  Title: "Titlu",
  Content: "Conținut",
  "Name should not be empty": "Câmpul cu numele nu trebuie să fie gol",

  /**
   * -------------------------------------------------------------------------------
   * - [Fields]
   * -------------------------------------------------------------------------------
   */
  Fields: "Culturi",
  "Field has been deleted!": "Câmpul a fost șters!",
  "Add field": "Adaugă cultură",
  "Edit field": "Editează cultura",
  "Name is not valid!": "Numele nu este valid",
  "New Fields": "Culturi noi",
  "Fields :": "Culturi:",
  "No field available": "Cultură indisponibilă",
  "Fields*": "Culturi*",
  "Text field": "Text cultură",

  /**
   * -------------------------------------------------------------------------------
   * - [Destinations]
   * -------------------------------------------------------------------------------
   */
  Destinations: "Destinații",
  "Add destination": "Adaugă destinație",
  "Edit destination": "Editează destinație",
  "Destination has been added!": "Destinația a fost adăugată cu succes!",
  "Destination has been modified!": "Destinația a fost modificată cu succes!",
  "Destination has been deleted!": "Destinația a fost ștearsă!",
  "Are you sure you want to delete this destination?":
    "Ești sigur(ă) că vrei să ștergi aceasta destinație?",
  "Country is not valid!": "Țara nu este validă!",
  "City is not valid!": "Orașul nu este valid!",
  "Location is not valid!": "Locația nu este validă!",
  State: "Județ",
  Cities: "Oraș",
  "Pick up location": "Locație întâlnire",
  "CountryId should not be empty": "Câmpul cu țara nu trebuie să fie gol",
  "CityId should not be empty": "Câmpul cu orașul nu trebuie să fie gol",
  "StateId should not be empty": "Câmpul cu județul nu trebuie să fie gol",
  "Location should not be empty":
    "Câmpul cu locația de întâlnire nu trebuie să fie gol",

  /**
   * -------------------------------------------------------------------------------
   * - [Drivers]
   * -------------------------------------------------------------------------------
   */
  Drivers: "Șoferi",
  "Add Driver": "Adaugă șofer",
  "Driver has been deleted!": "Șoferul a fost șters!",
  "Driver has been added!": "Șoferul a fost adăugat cu succes!",
  "Are you sure you want to delete this driver?":
    "Ești sigur(ă) că vrei să ștergi aceast șofer?",
  "Driver details": "Detalii sofer",
  "First name is not valid!": "Numele nu este valid!",
  "Last name is not valid!": "Prenumele nu este valid!",
  "Phone number is not valid": "Numărul de telefon este greșit",
  "Email is not valid": "Email-ul nu este valid",
  "CNP must contain only numbers!": "CNP-ul trebuie să contină doar numere!",
  "Vehicle field is not valid!": "Câmpul pentru mașină nu este valid!",
  "The address is not valid!": "Adresa nu este validă!",
  "Driver has no documents.": "Soferul nu are documente disponibile.",
  "Email must be an email": "Câmpul cu email-ul trebuie să conțină un email",

  /**
   * -------------------------------------------------------------------------------
   * - [Fleet]
   * -------------------------------------------------------------------------------
   */
  Fleet: "Flotă",
  "Vehicle list": "Listă vehicule",
  "Add fleet": "Adaugă mașină",
  "Vehicle has been deleted!": "Mașina a fost ștearsă!",
  "Vehicle has been added!": "Mașina a fost adăugată!",
  "Car brand is not valid!": "Brandul mașinii nu este valid!",
  "Car model is not valid!": "Modelul mașinii nu este valid!",
  "Registration plate is not valid!": "Numărul de înmatriculare nu este valid!",
  "Fabrication year must be a number!":
    "Anul fabricației trebuie să fie număr!",
  "Kilometers must be number!": "Kilometrii trebuie sa fie un număr!",
  "Vehicle has no documents.": "Nu sunt documente disponibile.",
  "Number of kilometres": "Număr de kilometri",
  "Vehicle has no gas credit card.":
    "Vehiculul nu are carduri de alimentare adăugate",
  "Gas credit card has been uploaded!": "Cardul de alimentare a fost adăugat",
  "Brand should not be empty": "Câmpul cu marca nu trebuie să fie gol",
  "Model should not be empty": "Câmpul cu modelul nu trebuie să fie gol",
  "RegistrationPlate should not be empty":
    "Câmpul cu numărul de înmatriculare nu trebuie să fie gol",
  "FabricationYear should not be empty":
    "Câmpul cu anul fabricației nu trebuie să fie gol",
  "Kilometres should not be empty":
    "Câmpul cu numărul de kilometri nu trebuie să fie gol",

  /**
   * -------------------------------------------------------------------------------
   * - [Trips]
   * -------------------------------------------------------------------------------
   */
  "CNPS document": "Act CNPS",
  "City hall document": "Act primărie",
  "Bachelor document": "Act Celibatar",
  "Client papers have been updated!": "Actele clientului au fost actualizați!",
  "Client papers couldn't been updated!":
    "Actele clientului nu au putut fi actualizați!",
  "You didn't select at least one option!":
    "Nu ați selectat cel puțin o opțiune!",
  "You have to pick at least one option!":
    "Trebuie să alegi cel puțin o opțiune!",
  "Add client papers": "Adăugați actele clientului",
  Papers: "Acte",
  Trips: "Curse",
  "Trip details": "Detalii cursă",
  "See trip": "Vezi cursa",
  "Additional information": "Informații adiționale",
  Departures: "Deplasări",
  Departure: "Deplasare",
  "Departure Place": "Loc de plecare",
  "Attendance sheet": "Fișă de prezență",
  "Add departure": "Adaugă deplasare",
  Taxes: "Taxe",
  Problems: "Probleme",
  Notes: "Note client",
  "Select the file": "Alegeți fișierul",
  "Departure date": "Dată plecare",
  Seats: "Locuri",
  "Nr. of seats": "Număr de locuri",
  Record: "Înscriere",
  "Departures list": "Listă curse tur",
  "Arrivals list": "Listă curse retur",
  "All trips": "Toate cursele",
  "Trips (departure)": "Curse (tur)",
  "Trips (arrival)": "Curse (retur)",
  Driver: "Șofer",
  "Departure trips": "Curse tur",
  "Arrival trips": "Curse retur",
  Arrival: "Retur",
  Field: "Câmp",
  "Time period": "Perioadă",
  "Payment status": "Suma plătită",
  "Arrival list": "Listă plecări retur",
  "Departure list": "Listă plecări tur",
  "Client has been added on trip!": "Clientul a fost adăugat pe cursă!",
  "Trip has been updated!": "Cursa a fost modificată!",
  "Are you sure you want to delete client from trip?":
    "Ești sigur că vrei să ștergi clientul de pe cursă?",
  "Are you sure you want to delete this trip?":
    "Ești sigur că vrei să ștergi această cursă?",
  "Filtering by fields": "Filtrare dupa culturi",
  "Filtering by periods": "Filtrare dupa perioade",
  "Filter by fields": "Filtrare dupa culturi",
  "Are you sure you want to mark the trip as finished?":
    "Sunteți sigur că doriți să marcați această cursă ca finalizată?",
  "Trip was succesfully marked as finished":
    "Cursa a fost marcată ca finalizată cu succes",
  Finished: "Finalizată",
  Arrived: "Cursă finalizată",
  "Finished Trip": "Cursă finalizată",
  "Add a date": "Adaugă o dată",
  "Sign Contract": "Semnează contract",
  "Add trip": "Adaugă cursă",
  "Trip added succesfully": "Cursa a fost adaugată cu succes",
  selected: "selectat",
  "Not available yet": "Indisponibil",
  Trip: "Cursă",
  "No trips available": "Nu sunt curse disponibile",
  "Second Pay Date": "Data ultimei plăți",
  "Not paid yet": "Neplătit",
  "Add last pay": "Adaugă ultima plată",
  "Final pay succesfully updated": "Plata finală a fost adăugată cu succes",
  "Departure should not be empty":
    "Câmpul cu data deplasării nu trebuie să fie gol",
  "Price should not be empty": "Câmpul cu prețul nu trebuie să fie gol",
  "DriverId should not be empty":
    "Câmpul cu numele șoferului nu trebuie să fie gol",
  "Sits should not be empty":
    "Câmpul cu numărul de locuri nu trebuie să fie gol",
  "Departure place should not be empty":
    "Câmpul cu locul de plecare nu trebuie să fie gol",
  "Find fields": "Caută cultură",
  "Departure period": "Perioadă plecare",
  "Start Departure Period": "Start perioadă de plecare",
  "End Departure Period": "Sfârșit perioadă de plecare",
  "Trip cancel was reversed succesfully":
    "Anularea finalizarii cursei a fost efectuata cu succes!",
  "Trip was finalized succesfully": "Cursa a fost finalizata cu succes!",
  "Are you sure you want to reverse the finalization of the trip?":
    "Sigur doriți să inversați finalizarea călătoriei?",

  "Ticket price": "Preț bilet",
  "CI EXPIRED": "CI EXPIRAT",
  /**
   * -------------------------------------------------------------------------------
   * - [Table]
   * -------------------------------------------------------------------------------
   */

  Filters: "Filtrează",
  Search: "Caută",
  "Loading...": "Se incarcă...",
  "No data available.": "Nu există date",

  /**
   * -------------------------------------------------------------------------------
   * - [Vehicle]
   * -------------------------------------------------------------------------------
   */

  Brand: "Marca",
  "Year of manufacture": "An de fabricatie",
  "Registration Plate": "Număr de înmatriculare",
  Insurance: "Asigurare",
  "Vehicle Details": "Detalii vehicul",
  "Edit vehicle": "Editeaza vehiculul",
  Vignettes: "Roviniete",
  Vignette: "Rovinietă",
  "Vignette (RO)": "Rovinieta Ro",
  "Gasoline credit cards": "Carduri de alimentare",

  /**
   * -------------------------------------------------------------------------------
   * - [Problems]
   * -------------------------------------------------------------------------------
   */
  "Add Problem": "Adaugă probleme",
  "New Problem": "Problemă nouă",
  "Relevant trip": "Cursă relevantă",
  "Describe the problem": "Descrie problema",
  "Block the client": "Blochează clientul",
  "Problema added succesfully": "Problemă adaugată cu succes",

  /**
   * -------------------------------------------------------------------------------
   * - [Notes]
   * -------------------------------------------------------------------------------
   */
  "Add Note": "Adaugă notă",
  "New Note": "Notă nouă",
  Note: "Notă",
  "Note should not be empty": "Câmpul notă nu trebuie să fie gol",

  /**
   * -------------------------------------------------------------------------------
   * - [Taxes]
   * -------------------------------------------------------------------------------
   */

  Type: "Tip",
  Paid: "Plătit",
  "Paid in Germany": "Plătit în Germania",
  "Paid taxes": "Plătit taxe",
  "Add taxes": "Adaugă taxe",
  "Filter by tax type": "Filtrare după taxe",
  "Gross cash": "Bani brut",
  "Tax return registration": "Înscriere recuperare taxe",
  "Tax Return": "Recuperare taxe",
  "Are you sure you want to delete this tax return?":
    "Sunteti sigur că doriți să ștergeți această intrare?",
  "Tax has been deleted!": "Taxa a fost ștearsă",

  /**
   * -------------------------------------------------------------------------------
   * - [Departures]
   * -------------------------------------------------------------------------------
   */
  "Choose status": "Alege statusul",
  "Find a field": "Găsește un câmp",
  "Culturi noi": "New Fields",
  "Departure added on client!": "Inscrierea a fost adăugată!",
  "Available periods:": "Perioadele disponibile:",
  "The client has no trip assigned to": "Clientul nu are o cursa asignată",
  "Contract is not signed yet": "Contractul nu a fost semnat",
  "Left to pay": "De plătit",
  "Fully paid": "Plătit integral",
  "Departure Registering": " Inscriere deplasare",
  "Contract signed date": "Data semnare contract",
  "Final date": "Data finala",
  "Number of weeks": "Număr de saptamani",
  "Hours worked daily": "Număr ore lucrate zilnic",
  "Add hours worked": "Adaugă ore lucrate",
  "Watched video": "Vizionat videoclip",
  "Delete client from route": "Șterge client de pe cursă",
  "Trip not established yet": "Cursa nu a fost stabilită",
  "First Pay Date": "Data primei plăți",
  "TimeId should not be empty": "Câmpul număr de zile nu trebuie să fie gol",
  "DestinationId should not be empty":
    "Câmpul loc de plecare nu trebuie să fie gol",

  /**
   * -------------------------------------------------------------------------------
   * - [Departures]
   * -------------------------------------------------------------------------------
   */

  Description: "Descriere",
  "Available dates": "Date disponibile",
  "Payment Type": "Tipul plății",
  Payments: "Plăți",
  "Payment Name": "Numele plății",
  "Payment Date": "Data plății",
  "Amount Paid": "Suma plătită",
  "Add new payment": "Adaugă plată nouă",
  "Add payment": "Adaugă plată",
  "Payment added succesfully": "Plată adăugată cu succes",
  "Payment deleted succesfully": "Plată ștearsă cu succes",
  "The departure was edited successfully":
    "Deplasarea a fost editată cu succes",
  CityHall: "Primărie",
  "Trip reasons": "Motive anulare de pe cursa",
  /**
   * -------------------------------------------------------------------------------
   * - [Settings]
   * -------------------------------------------------------------------------------
   */
  Create: "Creare",
  Visualize: "Vizualizare",
  Edit: "Editare",
  Delete: "Stergere",
  Settings: "Setări",
  "Permissions settings": "Setări pentru permisiuni",
  "Create new account": "Crează cont",
  Role: "Rol",
  Password: "Parolă",
  "Manage your settings": "Administreză-ți setările",

  /**
   * -------------------------------------------------------------------------------
   * - [Users]
   * -------------------------------------------------------------------------------
   */
  User: "Utilizator",
  Users: "Utilizatori",
  "Manage your users": "Administreză-ți utilizatorii",
  "Are you sure you want to delete this user?":
    "Ești sigur că vrei să ștergi acest utilizator?",
  "User has been deleted!": "Utilizatorul a fost șters!",
  "The user has been successfully added!": "Utilizatorul a fost adaugăt!",
  "Password should not be empty": "Câmpul cu parola nu trebuie să fie gol",
  "RoleId should not be empty":
    "Câmpul cu rolul utilizatorului nu trebuie să fie gol",

  /**
   * -------------------------------------------------------------------------------
   * - [Roles]
   * -------------------------------------------------------------------------------
   */
  "Add role": "Adaugă rol",
  "Role name": "Nume rol",
  "Role has been added successfully!": "Rolul a fost adaugat cu succes!",
  "Permission count": "Număr permisiuni",
  Roles: "Roluri",
  SeeTasks: "Vizualizare task-uri",
  AlterTasks: "Editare task-uri",
  SeeTax: "Vizualizare taxe",
  AlterTax: "Editare taxe",
  SeeClients: "Vizualizare clienți",
  AlterClients: "Editare clienți",
  SeeRoutes: "Vizualizare curse",
  AlterRoutes: "Editare curse",
  SeeFleets: "Vizualizare flotă",
  AlterFleets: "Editare flotă",
  SeeDrivers: "Vizualizare șoferi",
  AlterDrivers: "Editare șoferi",
  SeeDepartures: "Vizualizare deplasări",
  AlterDepartures: "Editare deplasări",
  SeeTags: "Vizualizare tag-uri",
  AlterTags: "Editare tag-uri",
  SeeFields: "Vizualizare culturi",
  AlterFields: "Editare culturi",
  SeeDestinations: "Vizualizare destinații",
  AlterDestinations: "Editare destinații",
  SeeUsers: "Vizualizare utilizatori",
  AlterUsers: "Editare utilizatori",
  "Are you sure you want to delete this role?":
    "Sunteți sigur că dorți să stergeți acest rol?",
  "Role has been deleted!": "Rolul a fost șters",
  "No data available": "Nu sunt date disponibile",
  "Date filter": "Filtrare după dată",
  Filter: "Filtrează",

  /**
   * -------------------------------------------------------------------------------
   * - [Reports]
   * -------------------------------------------------------------------------------
   */
  "Monthly Report": "Raport lunar",
  "Download Report": "Descarcă raport",
  Reports: "Rapoarte",
  "Daily Report": "Raport zilnic",
  "Start Date": "Data de inceput",
  "End Date": "Data de sfârșit",
  "Select a period": "Selectați o perioadă",
  "Full Report": "Raport complet",
  "Name of the report": "Numele raportului",
  "Payments Report": "Raport Plăți",

  /**
   * General
   */
  "Filter by users": "Filtrează dupa utilizatori",
  "Created at": "Creat la data",

  Responsible: "Responsabil",
  Administration: "Administrare",
  Country: "Țară",
  City: "Oraș",
  Location: "Locație",
  Actions: "Acțiuni",
  Age: "Vârstă",
  "Hire date": "Dată angajare",
  Name: "Nume",
  Surname: "Prenume",
  Address: "Adresă",
  "Address*": "Adresă*",
  "First name": "Prenume",
  "Last name": "Nume",
  Vehicle: "Vehicul",
  Add: "Adaugă",
  Block: "Blochează",
  Unblock: "Deblochează",
  Finalize: "Finalizare",
  Quit: "Renunță",
  Years: "Ani",
  Year: "An",
  Birthday: "Zi de naștere",
  Car: "Mașina",
  "Register plate": "Număr înmatriculare",
  Kilometers: "Kilometri",
  Male: "Bărbat",
  Female: "Femeie",
  FEMALE: "Femeie",
  MALE: "Bărbat",
  Price: "Preț",
  Destination: "Destinație",
  "Phone Number": "Număr de telefon",
  Comments: "Comentarii",
  Nomenclators: "Nomenclatoare",
  Details: "Detalii",
  "Tax returns": "Returnare taxe",
  Week: "Săptămâna",
  Payed: "Achitat",
  "Payed Germany": "Achitat Germania",
  "Payed UnionTax": "Achitat UnionTax",
  Comission: "Comision",
  Gross: "Brut",
  Value: "Valoare",
  "Period succesfully updated": "Perioada contractuală actulizată cu succes",
  Reason: "Motiv",
  "Last Leave": "Ultima plecare",
  "Client Tags": "Tag-urile clientului",
  "Not established yet": "Nu a fost stabilit incă",
  "Confirm action": "Confirmă",
  Yes: "Da",
  No: "Nu",
  Hours: "Ore lucrate",

  Neutral: "Neutru",

  /**
   * -------------------------------------------------------------------------------
   * - [Signature]
   * -------------------------------------------------------------------------------
   */

  "Step 1: Initialize Signing Process":
    "Pasul 1: Ințializarea procesului de semnare",
  "Start the signing process": "Începe procesul de semnare",
  "Start the signature pad": "Pornește tableta pentru semnătură",
  "Are you sure you turned on the signature pad before proceding?":
    "Sunteți sigur că ați pornit tableta de semnătură înainte de a continua?",
  Next: "Continuă",
  Previous: "Înapoi",
  "The signing process has started": "Procesul de semnare a început",
  "An error ocurred while starting the signature pad":
    "A intervenit o eroare la ponirea tabletei pentru semnat!",
  "Signature cannot be empty": "Semnătura nu poate fi inexistentă!",
  "Signature was saved succesfully. Please proceed to the next step!":
    "Semnătura a fost salvată cu succes. Va rugăm să treceți la următorul pas!",
  "Signature pad is not open or there was no signature available!":
    "Tableta pentru semnătură nu este pornită sau nu există o semnătură disponibilă!",
  "Step 2: Save Signature": "Pasul 2: Salvarea semnăturii!",
  "Save signature": "Salvează semnătura!",
  "Are you sure you saved the signature before going to the next step?":
    "Sunteți sigur că ați salvat semnătura înainte de a trece la pasul următor?",
  "Step 3: Visualize signature": "Pasul 3: Vizualizarea semnăturii",
  "There is no signature available": "Nu există o semnătura valabila!",
  "Redo Signature": "Refă semnătura",
  "Generate doc": "Generează document",
  "Sign the document": "Semnează documentul",
  "Signature was saved succesfully": "Semnătura a fost salvată cu succes!",
  "The signature could not be saved. Please try again":
    "Semnătura nu a putut fi salvată. Vă rugăm să încercați din nou!",
  "Scan IC": "Scanare CI",
  "IC scanned succesfully": "CI a fost scanată cu succes!",
  "Could not find any document on the scanner":
    "Nu a fost găsit niciun document pe scanner!",

  /**
   * -------------------------------------------------------------------------------
   * - [Registru Intrari/Iesiri]
   * -------------------------------------------------------------------------------
   */

  "Registration Number": "Nr. de înregistrare",
  "Registration Date": "Data înregistrării",
  "Registration number & Document Date": "Nr. și data documentului",
  "Where the document comes from": "De unde provine documentul",
  "Brief content of the document": "Conținutul pe scurt al documentului",
  "The compartment to which the document and the receiving signature were assigned":
    "Compartimentul căruia i s-a repartizat documentul și semnătura de primire",
  "Expedition Date": "Data expedierii",
  Recipient: "Destinatar",
  "Add register": "Adaugă registru",
  "In/Out Register": "Registru Intrări/Ieșiri",
  Register: "Registru",
  "Reg. No. to which they connect. doc. and the No.":
    "Nr. de înregistrare la care se conec. doc. și indic. dvs",
  "The date must be greater than the last element":
    "Data aleasă trebuie să fie mai mare decât ultima înregistrare din registru!",
  "Source should not be empty":
    "Câmpul 'De unde provine documentul' nu poate fi gol!",
  "Details should not be empty":
    "Câmpul 'Conținutul pe scurt al documentului' nu poate fi gol!",
  "Responsible should not be empty":
    "Câmpul 'Compartimentul căruia i s-a repartizat documentul și semnătura de primire' nu poate fi gol!",
  "Are you sure you want to delete this? This is irreversible!":
    "Sunteți sigur că doriți să ștergeți? Această acțiune este ireversibilă!",
  "Succesfully deleted!": "Șters cu succes!",
};

export default translation;
