import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "api/axios";
import { Typography, Box, Grid, Tooltip } from "@mui/material";

import { CustomDialog } from "lib";
import { toast } from "react-toastify";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";

import BrushIcon from "@mui/icons-material/Brush";
import SignatureModal from "./SignatureModal";

const LinkToGoogleModal = (props) => {
  const {
    open,
    setOpen,
    linkCallback,
    scannedImage,
    setScannedImage = () => {},
    clientData,
  } = props;
  let { id } = useParams();
  const { t } = useTranslation();
  const [docs, setDocs] = useState([]);
  // const [signatureImage, setSignatureImage] = useState(null);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);

  const getDocsFromGoogle = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_GOOGLE_API_URL}/all`
      );
      return res.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  useEffect(() => {
    getDocsFromGoogle().then((docs) => setDocs(docs));
  }, []);

  const submitNewId = async (docId, docName) => {
    try {
      await axios.patch(`/clients/updateGoogleDoc/${id}`, null, {
        params: {
          type: open,
          googleDocId: docId,
          source: "LUCICOSM SRL",
          details: "CONTRACT MEDIERE",
          responsible: clientData.firstName + " " + clientData.lastName,
          contractName: docName,
        },
      });

      linkCallback();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={Boolean(open)}
      title={t("Select document from Google")}
      button1={t("Finalize")}
      button2={t("Quit")}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "30rem",
        }}
      >
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          {docs.map((doc) => (
            <Box
              key={doc.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#f4f4f4",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                }}
              >
                {doc.name}
              </Typography>
              <div style={{ display: "flex", gap: "10px" }}>
                <Tooltip title="Alege PDF">
                  <ContactPageIcon
                    onClick={() => {
                      submitNewId(doc.id, doc.name);
                      toast.success("Succes");
                      setOpen(false);
                    }}
                    sx={{ color: "#5664D2", cursor: "pointer" }}
                  />
                </Tooltip>
                <Tooltip title="Adauga semnatura">
                  <BrushIcon
                    onClick={() => {
                      submitNewId(doc.id);
                      setOpenSignatureModal(true);
                    }}
                    sx={{ color: "#E91E63", cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            </Box>
          ))}
        </Grid>
      </Box>

      {openSignatureModal && (
        <SignatureModal
          open={openSignatureModal}
          setOpen={setOpenSignatureModal}
          signatureImage={scannedImage}
          setSignatureImage={setScannedImage}
        />
      )}
    </CustomDialog>
  );
};

export default LinkToGoogleModal;
