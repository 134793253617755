import React, { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import PropTypes from "prop-types";
import useWindowDimensions from "hooks/useWindowDimensions";

/**
 * Search component
 * @param {*} search - state for the search input
 * @param {*} setSearch - set the state function
 * @param {*} nameToFind - placeholder value
 * @param {object} style - object to override styles
 */
const SearchComponent = ({
  isSearch,
  setIsSearch = () => {},
  search,
  setSearch = () => {},
  nameToFind,
  style,
  triggerSearch,
  setTriggerSearch = () => {},
  triggerRefetch,
  setTriggerRefetch = () => {},
  setLoading = () => {},
  setterFunction = () => {},
  resetFilter = () => {},
}) => {
  const defaultStyle = {
    width: "100%",

    display: "flex",
    justifyContent: "center",
    padding: "1px 10px",
    position: "relative",
    borderRadius: "1rem",

    backgroundColor: "#FFFFFF26",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    marginLeft: 0,

    border: "1px solid #eaeaea",
  };

  const handleRemoveSearch = () => {
    setIsSearch(false);
    setLoading(true);
    setterFunction([]);
    setTriggerRefetch(!triggerRefetch);
  };

  useEffect(() => {
    if (search === "" && isSearch) {
      handleRemoveSearch();
    }
  }, [search]);

  const handleSearch = () => {
    if (search !== "") {
      resetFilter();
      setLoading(true);
      setterFunction([]);
      setIsSearch(true);
      setTriggerSearch(!triggerSearch);
    }
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <TextField
      id="search-input"
      placeholder={nameToFind}
      sx={{ ...defaultStyle, ...style }}
      variant="standard"
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
      autoComplete="off"
      onKeyDown={keyDownHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {!isSearch ? (
              <SearchIcon
                sx={{
                  color: search !== "" && "#17ad00",
                  transform: search !== "" && "scale(1.1,1.1)",
                  transition: search !== "" && ".5s",
                  cursor: search !== "" && "pointer",
                }}
                onClick={search !== "" ? () => handleSearch() : undefined}
              />
            ) : (
              <ClearIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                  handleRemoveSearch();
                }}
              />
            )}
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

SearchComponent.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  nameToFind: PropTypes.string,
  style: PropTypes.object,
};

SearchComponent.defaultProps = {
  search: "",
  setSearch: () => {},
  nameToFind: "Default placeholder",
  style: {},
};

export default SearchComponent;
