import React, { useState, useEffect } from "react";
import { CustomDialog } from "lib";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getCountries, getStates, getCities, getTags } from "api/API";

const DatePickerFilter = ({
  open = false,
  setOpen = () => {},
  setIsFilter = () => {},
  triggerFilter = false,
  setTriggerFilter = () => {},
  setData,
  setLoading,
  filterData = null,
  setFilterData = () => {},
  resetFilter = () => {},
  triggerRefetch = null,
  setTriggerRefetch = () => {},
  resetSearch = null,
}) => {
  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [tags, setTags] = useState([]);

  const handleChange = async (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "countryId") await getAndSetStates(e.target.value);
    if (e.target.name === "stateId")
      await getAndSetCities(filterData.countryId, e.target.value);
  };

  const handleChangeAutocomplete = (event, value) => {
    setFilterData({
      ...filterData,
      tagId: value.id,
    });
  };

  useEffect(() => {
    getCountries().then((countries) => setCountries(countries));
    getTags().then((tag) => setTags(tag));
  }, []);

  const getAndSetStates = async (countryId) => {
    getStates(countryId).then((states) => setStates(states));
  };

  const getAndSetCities = async (countryId, stateId) => {
    getCities(countryId, stateId).then((cities) => setCities(cities));
  };

  const genders = [
    {
      id: 1,
      name: t("Male"),
      value: "MALE",
    },
    {
      id: 2,
      name: t("Female"),
      value: "FEMALE",
    },
    {
      id: 3,
      name: t("Neutral"),
      value: "Neutral",
    },
  ];

  const handleRemoveFilters = () => {
    resetFilter();
    setFilterData({ birthday: null });
    setLoading(true);
    setData([]);
    setTriggerRefetch(!triggerRefetch);
    setOpen(false);
  };

  const handleFilter = () => {
    resetSearch();
    setLoading(true);
    setData([]);
    setIsFilter(true);
    setTriggerFilter(!triggerFilter);
    setOpen(false);
  };

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Filter")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={handleFilter}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          columns={{ xs: 4, md: 12 }}
        >
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Filter after birthday")}
                name="birthday"
                value={filterData?.birthday}
                onChange={(value) => {
                  handleChange({ target: { name: "birthday", value } });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              select
              label={t("Country")}
              onChange={handleChange}
              name="countryId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              select
              label={t("State")}
              onChange={handleChange}
              name="stateId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {states.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              select
              label={t("Cities")}
              onChange={handleChange}
              name="cityId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {cities.map((city) => (
                <MenuItem key={city.id} value={city.id}>
                  {city.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="Gender"
              value={filterData.gender}
              onChange={handleChange}
              id="outlined-required"
              label={t("Gender")}
            >
              {genders.map((gender) => (
                <MenuItem key={gender.id} value={gender.value}>
                  {gender.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="contactPerson"
              value={filterData.contactPerson}
              onChange={handleChange}
              id="outlined-required"
              label={t("Contact Person")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="CIP"
              value={filterData.CIP}
              onChange={handleChange}
              id="outlined-required"
              label={t("CIP")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="country-select-demo"
              // sx={{ width: 300 }}
              options={tags}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              onChange={handleChangeAutocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Choose a tag")}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="age"
              value={filterData.age}
              onChange={handleChange}
              id="outlined-required"
              label={t("Age")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{
                borderRadius: "1rem",
              }}
              onClick={handleRemoveFilters}
            >
              Sterge filtre
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default DatePickerFilter;
