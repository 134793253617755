import React, { useState, useMemo } from "react";
import DetailsComponet from "../DetailsComponent/DetailsComponet";
import i18n from "i18n";
import { useConfirm } from "hooks/useConfirm";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadIcon from "@mui/icons-material/Upload";

import LinkIcon from "@mui/icons-material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinkToGoogleModal from "./LinkToGoogleModal";
import axios from "axios";
import { capitalize } from "utils/functions";
import { useTranslation } from "react-i18next";
import { uploadClientDoc, uploadContractToAws } from "api/clientApi";

/**
 * This components is a details component for documents
 * Does oll the calculations when given:
 * 1. Document types
 * 2. List of documents
 * Does like an intersection on those 2
 */

const DocumentDetails = ({
  title = i18n.t("Documents"),
  docTypes = [],
  getDocs = () => {},
  deleteDoc = () => {},
  docs = [],
  fileUrl = "",
  setDocs = () => {},
  clientData = {},
  setDocumentModal = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [isDocScanned, setIsDocScanned] = useState(false);

  //this is the image with the signature
  const [scannedImage, setScannedImage] = useState({
    isNewSignature: false,
    clientSignature: null,
    legalSignature: null,
  });

  const confirm = useConfirm();
  const { t } = useTranslation();

  /**
   * Documents data & render
   */
  const documentData = useMemo(() => {
    /**
     * Intersect types with actual documents
     */
    let newDocumentData = docTypes.reduce((data, el) => {
      /**
       * Discard OTHER type, theese come from the docs
       */

      if (el === "OTHER") {
        return data;
      }

      let docOfSameType = docs.filter((doc) => doc.type === el);

      if (!docOfSameType.length) {
        return {
          ...data,
          [el]: {
            type: el,
            docs: null,
          },
        };
      }

      return {
        ...data,
        [el]: docOfSameType[0],
      };
    }, {});

    newDocumentData = {
      ...newDocumentData,
      ...docs.reduce((data, d) => ({ ...data, [d.docs.name]: d }), {}),
    };

    return newDocumentData;
  }, [docTypes, docs]);

  const downloadAndSendContractToBackend = async (link, docId) => {
    try {
      const response = await fetch(link);
      const pdfBlob = await response.blob();
      const formData = new FormData();
      formData.append("pdf", pdfBlob, "original_file.pdf");
      formData.append("clientId", clientData.id);
      formData.append("googleDocId", docId);

      uploadContractToAws(formData).then((res) => {
        if (res.ok) {
          getDocs().then((docs) => {
            setDocs(docs.length > 0 ? docs : []);
          });
        } else {
          toast.error(
            "A intervenit o eroare la incarcarea contractului generat!"
          );
        }
      });
    } catch (error) {
      console.error("Error downloading or uploading the file", error);
    }
  };

  const generatePDF = async (docId, type) => {
    const formData = new FormData();

    const data = {
      clientId: clientData.id,
      googleDocId: docId,
      source: "Lucicosm SRL",
      details: "contract mediere",
      clientDocsType: "OTHER",
      responsible: capitalize(clientData.lastName + " " + clientData.firstName),
      "Person.Name": capitalize(
        clientData.lastName + " " + clientData.firstName
      ),
      "Person.SerienrCi": clientData.series + " " + clientData.ICNumber,
      "Person.Cnp": clientData.CNP,
      "Person.Phone": clientData.phone,
      "Address.City": clientData.Adress,
      "Person.LastName": capitalize(clientData.lastName),
      "Person.FirstName": capitalize(clientData.firstName),
      "Person.Birthday": ` ${new Date(clientData.birthday).getUTCDate()} - ${
        new Date(clientData.birthday).getUTCMonth() + 1
      } - ${new Date(clientData.birthday).getUTCFullYear()}  `,
      "Person.BirthAdress": clientData.birthPlace,

      isNewSignature: scannedImage.isNewSignature,
      ClientSignature: " ",
      LegalSignature: " ",
    };

    formData.append("data", JSON.stringify(data));
    formData.append("LegalSignature", scannedImage.legalSignature);
    formData.append("ClientSignature", scannedImage.clientSignature);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_GOOGLE_API_URL}/pdf/${docId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data) {
        window.open(res.data, "_blank");
        downloadAndSendContractToBackend(res.data, docId);
        setScannedImage({
          isNewSignature: false,
          clientSignature: null,
          legalSignature: null,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const documentRender = useMemo(() => {
    const cellModifier = (doc) => (
      <div style={{ display: "flex", gap: ".5rem" }}>
        {/* Documents that are binded to Google Docs API */}
        {["GDPR", "CONTRACT", "CITYHALL", "BACHELOR", "OTHER"].indexOf(
          doc?.type
        ) >= 0 && (
          <>
            {/* checking if the doc has docs attached or an s3 link available so we wont display them on the already generated contracts */}
            {(doc.docs === null || doc.s3Link === null) && (
              <Tooltip title="Connect to Google Docs">
                <LinkIcon
                  style={{ cursor: "pointer" }}
                  color="successCustom"
                  onClick={() => setOpen(doc.type)}
                />
              </Tooltip>
            )}

            {doc.googleDocId && doc.s3Link === null && (
              <Tooltip title="Generează PDF">
                <PictureAsPdfIcon
                  style={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() => {
                    toast.promise(
                      async () => {
                        await generatePDF(doc.googleDocId, doc.type);
                      },
                      {
                        pending: t(
                          "Generating PDF, it takes about 10 seconds."
                        ),
                      }
                    );
                  }}
                />
              </Tooltip>
            )}
          </>
        )}

        {/* Status icon */}
        {Boolean(doc?.docs?.fileId) ? (
          <Tooltip title="Uploaded">
            <CheckCircleIcon color="successCustom" />
          </Tooltip>
        ) : (
          <Tooltip title="Not uploaded">
            <CancelIcon color="errorCustom" />
          </Tooltip>
        )}

        {/* View file icon */}
        {Boolean(doc.docs?.fileId) ? (
          <a
            rel="noreferrer"
            target={"_blank"}
            href={`${process.env.REACT_APP_API_BASE}${fileUrl}${doc.docs.fileId}`}
          >
            <Tooltip title="View file">
              <AttachFileIcon color="primary" />
            </Tooltip>
          </a>
        ) : (
          <Tooltip title="Upload file">
            <UploadIcon
              style={{ cursor: "pointer" }}
              color="primary"
              onClick={() => setDocumentModal(doc)}
            />
          </Tooltip>
        )}

        <>
          {/* Delete Icon */}
          {Boolean(doc.docs?.fileId) && (
            <>
              {Boolean(doc.type) && doc.type !== "OTHER" ? (
                <Tooltip title="Edit file">
                  <UploadIcon
                    style={{ cursor: "pointer" }}
                    color="primary"
                    onClick={() => setDocumentModal(doc)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Delete file">
                  <DeleteIcon
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      confirm(
                        `Ești sigur că vrei să ștergi documentul: ${doc.docs.name}?`,
                        () => {
                          deleteDoc(doc.docs.id, () => {
                            getDocs().then((docs) => {
                              setDocs(docs.length > 0 ? docs : []);
                              toast.success(
                                `Documentul ${doc.docs.name} a fost șters!`
                              );
                            });
                          });
                        }
                      )
                    }
                    color="errorCustom"
                  />
                </Tooltip>
              )}
            </>
          )}
        </>
      </div>
    );

    const docMapper = {
      IC: "CI",
      PASSPORT: "Pasaport",
      RECORD: "Cazier",
      CONTRACT: "Contract",
      GDPR: "GDPR",
      CITYHALL: "Primaria",
      BACHELOR: "Celibatar",
    };

    let newDocumentRender = docTypes.reduce(
      (render, d) => [
        ...render,
        {
          key: d,
          label: docMapper[d],
          cellModifier,
        },
      ],
      []
    );

    newDocumentRender = [
      ...newDocumentRender,
      ...docs.reduce(
        (render, d) => [
          ...render,
          {
            key: d.docs.name,
            label: d.docs.name,
            cellModifier,
          },
        ],
        []
      ),
    ];

    return newDocumentRender;
  }, [confirm, docTypes, docs, scannedImage]);

  const transformBase64imgToFormData = (documentPhoto) => {
    // Create a Blob from base64 data
    const blob = new Blob(
      [Uint8Array.from(atob(documentPhoto), (c) => c.charCodeAt(0))],
      { type: "image/jpeg" }
    );

    const formData = new FormData();

    formData.append("name", "IC");
    formData.append("type", "IC");
    formData.append(
      "file",
      blob,
      `imageOf${clientData.firstName + "_" + clientData.lastName}.jpg`
    );

    uploadClientDoc(clientData.id, formData, () => {
      getDocs().then((docs) => {
        setDocs(docs.length > 0 ? docs : []);
      });
    });
  };

  //this function will scan the IC of the client and will add the document to his folder
  const scanCI = async () => {
    try {
      setIsDocScanned(true);
      const response = await fetch(
        `${process.env.REACT_APP_SCANNER_SIGNATURE_URL}/api/scan`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET",
            "Content-Type": "application/json",
          },
        }
      );

      const scannedData = await response.json();

      if (scannedData.scanState === "Scan completed") {
        transformBase64imgToFormData(scannedData.idPhoto);
        toast.success(t("IC scanned succesfully"));
      } else {
        throw new Error("Eroare");
      }
    } catch (error) {
      toast.error(t("Could not find any document on the scanner"));
    } finally {
      setIsDocScanned(false);
    }
  };

  return (
    <>
      <DetailsComponet
        title={title}
        showAdd
        onAdd={() => setDocumentModal(true)}
        tableData={documentData}
        render={documentRender}
        showScanCI
        onScanCI={scanCI}
        loadingScanCI={isDocScanned}
      />
      <LinkToGoogleModal
        open={open}
        setOpen={setOpen}
        scannedImage={scannedImage}
        setScannedImage={setScannedImage}
        clientData={clientData}
        linkCallback={() =>
          getDocs().then((docs) => {
            setDocs(docs.length > 0 ? docs : []);
          })
        }
      />
    </>
  );
};

export default DocumentDetails;
