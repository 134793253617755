import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Container,
  Typography,
  InputBase,
  Stack,
  styled,
  alpha,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import Search from "../NewSearchBar/Search";
import { useTranslation } from "react-i18next";
import Search from "lib/components/NewSearchBar/SearchComponent";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import useWindowDimensions from "hooks/useWindowDimensions";

function CustomTablePagination({
  title,
  showSearchbar,
  onSearch,
  showFilters,
  showAdd,
  showExport,
  onAdd,
  onFilters,
  onExport,
  labels,
  tableData,
  cellModifier,
  showDatePickerFilter,
  onDatePickerFilter,
  loading,
  setLoading = () => null,
  getterFunction,
  setterFunction,
  triggerRefetch,
  setTriggerRefetch = () => null,
  searchFunction = () => null,
  ComponentUsedForFiltering = null,
  filterData = null,
  setFilterData = () => {},
  filterFunction = () => {},
  additionalId = false,
}) {
  const { vw } = useWindowDimensions();
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesToLoad, setPagesToLoad] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const indexOfLastRecord = currentPage * rowsPerPage;

  const { t } = useTranslation();

  const [query, setQuery] = useState("");

  const [openFilter, setOpenFilter] = useState(false);

  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);

  const resetFilter = () => {
    setIsFilter(false);
  };

  const resetSearch = () => {
    setIsSearch(false);
  };

  // page change handler
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // rows per page change handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  useEffect(() => {
    if (isFilter) {
      filterFunction(filterData).then((res) => {
        setCount(res.length);
        setterFunction(res);
        setLoading(false);
        // setFilterData({});
      });
    } else if (isSearch) {
      if (additionalId) {
        searchFunction(query, additionalId).then((res) => {
          setCount(res.length);
          setterFunction(res);
          setLoading(false);
        });
      } else {
        searchFunction(query).then((res) => {
          setCount(res.length);
          setterFunction(res);
          setLoading(false);
        });
      }
    } else {
      getterFunction(rowsPerPage, currentPage, pagesToLoad).then((res) => {
        setCount(res.count);
        setterFunction(res.content);
        setLoading(false);
      });
    }
  }, [currentPage, rowsPerPage, triggerRefetch, triggerSearch, triggerFilter]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "1rem" }}>
      <Container
        sx={{
          minWidth: "100%",
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 0,
          flexDirection: vw < 650 && "column",
          gap: "10px",
        }}
      >
        <Typography variant="tableTitle">{title}</Typography>
        {showSearchbar && (
          <Search
            nameToFind="Caută"
            triggerRefetch={triggerRefetch}
            setTriggerRefetch={setTriggerRefetch}
            isFilter={isFilter}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
            search={query}
            setSearch={setQuery}
            triggerSearch={triggerSearch}
            setTriggerSearch={setTriggerSearch}
            setterFunction={setterFunction}
            setLoading={setLoading}
            setIsFilter={setIsFilter}
            resetFilter={resetFilter}
            sx={{ backgroundColor: "red" }}
          />
        )}

        <Stack direction="row" spacing={2}>
          {showAdd && (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={onAdd}
            >
              {t("Add")}
            </Button>
          )}

          {showFilters && (
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={onFilters}
            >
              {t("Filters")}
            </Button>
          )}
          {showDatePickerFilter && (
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={() => {
                setOpenFilter(true);
                setFilterData({ birthday: null });
              }}
            >
              {t("Filters")}
            </Button>
          )}

          {showExport && (
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={onExport}
            >
              Export
            </Button>
          )}
        </Stack>
      </Container>
      <Divider />
      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {labels.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth || 10 }}
                  onClick={column.onClick}
                  sx={{ color: "textCustom.secondary", fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={labels.length}>
                  {/* <Typography
                    sx={{
                      color: "textCustom.secondary",
                      textAlign: "center",
                      padding: "5rem 1rem",
                    }}
                  >
                    {t("Loading...")}
                  </Typography> */}
                  <LoadingSpinner margin="auto" loading={loading} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!tableData.length ? (
                  <TableRow>
                    <TableCell colSpan={labels.length}>
                      <Typography
                        sx={{
                          color: "textCustom.secondary",
                          textAlign: "center",
                          padding: "5rem 1rem",
                        }}
                      >
                        {t("No data available.")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : //if we have the isSearch true, then we will make the pagination ourseveles, not the one from backend
                isSearch || isFilter ? (
                  tableData
                    .slice(
                      currentPage * rowsPerPage,
                      currentPage * rowsPerPage + rowsPerPage
                    )
                    .map((row) => {
                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {labels.map((column) => {
                              const value = row[column.id];

                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  sx={{
                                    color: "textCustom.secondary",
                                  }}
                                  style={
                                    Boolean(row.info)
                                      ? {
                                          borderBottom: "none",
                                        }
                                      : {}
                                  }
                                >
                                  {cellModifier
                                    ? cellModifier(row, column, value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>

                          {Boolean(row.info) && (
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "textCustom.secondary",
                                  padding: 0,
                                }}
                                colSpan={labels.length}
                              >
                                {row.info}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })
                ) : (
                  //else we re using the one we get from backend
                  tableData.map((row) => {
                    return (
                      <>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {labels.map((column) => {
                            const value = row[column.id];

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                sx={{
                                  color: "textCustom.secondary",
                                }}
                                style={
                                  Boolean(row.info)
                                    ? {
                                        borderBottom: "none",
                                      }
                                    : {}
                                }
                              >
                                {cellModifier
                                  ? cellModifier(row, column, value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>

                        {Boolean(row.info) && (
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "textCustom.secondary",
                                padding: 0,
                              }}
                              colSpan={labels.length}
                            >
                              {row.info}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* if we dont have a component for filtering used, then we will display
      nothing so it doesnt crash */}

      {ComponentUsedForFiltering === null ? (
        <></>
      ) : (
        <ComponentUsedForFiltering
          open={openFilter}
          setOpen={setOpenFilter}
          isFilter={isFilter}
          setIsFilter={setIsFilter}
          triggerFilter={triggerFilter}
          setTriggerFilter={setTriggerFilter}
          setIsSearch={setIsSearch}
          setLoading={setLoading}
          setData={setterFunction}
          filterData={filterData}
          setFilterData={setFilterData}
          triggerRefetch={triggerRefetch}
          setTriggerRefetch={setTriggerRefetch}
          resetSearch={resetSearch}
          resetFilter={resetFilter}
        />
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: "1rem",
//   padding: "0 8rem",
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
//   border: "1px solid #eaeaea",
// }));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

// prop types
CustomTablePagination.propTypes = {
  title: PropTypes.string,
  showSearchbar: PropTypes.bool,
  showFilters: PropTypes.bool,
  showExport: PropTypes.bool,
  showAdd: PropTypes.bool,
  cellModifier: PropTypes.func,
  onAdd: PropTypes.func,
  onFilters: PropTypes.func,
  onExport: PropTypes.func,
  onSearch: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      minWidth: PropTypes.number,
      onClick: PropTypes.func,
    })
  ),
  tableData: PropTypes.array,
  loading: PropTypes.bool,
  getterFunction: PropTypes.func,
  setterFunction: PropTypes.func,
  triggerRefetch: PropTypes.bool,
  isSearch: PropTypes.bool,
  searchFunction: PropTypes.func,
};

// default props
CustomTablePagination.defaultProps = {
  title: "Your title",
  showSearchbar: false,
  showFilters: false,
  showExport: false,
  showAdd: false,
  cellModifier: () => {},
  onAdd: () => {},
  onExport: () => {},
  onFilters: () => {},
  onSearch: () => {},
  loading: false,
  labels: [
    {
      onClick: () => {},
      id: "id",
      label: "ID",
      minWidth: 100,
    },
  ],
  tableData: [],
  getterFunction: () => {},
  setterFunction: () => {},
};

export default CustomTablePagination;
