import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";
import { toast } from "react-toastify";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { useUser } from "hooks/useUser";
import { getDOB } from "utils/functions";

import {
  Typography,
  Breadcrumbs,
  Link,
  Box,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";

import { CustomTable, CustomDialog } from "lib";
import { addDriver, getAllDrivers, deleteDriver, getCars } from "api/API";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

function Drivers() {
  const navigate = useNavigate();
  const [driverModal, setDriverModal] = useState(false);
  const [driverData, setDriverData] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [loadingDrivers, setLoadingDrivers] = useState(true);
  const [cars, setCars] = useState([]);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { can } = useUser();

  const canViewDrivers = can(10);
  const canAlterDrivers = can(11);

  const labels = [
    {
      id: "firstName",
      label: t("First name"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "lastName",
      label: t("Last name"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "vehicle",
      label: t("Vehicle"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "age",
      label: t("Age"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "hireDate",
      label: t("Hire date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions")
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`./driver/` + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          {canAlterDrivers && (
            <>
              &nbsp; &nbsp; &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  confirm(
                    t("Are you sure you want to delete this driver?"),
                    () =>
                      deleteDriver(row.id, () =>
                        getAllDrivers().then((drivers) => {
                          setDrivers(drivers);
                          toast.success(t("Driver has been deleted!"));
                        })
                      )
                  )
                }
              >
                <DeleteIcon color="errorCustom" />
              </span>
            </>
          )}
        </>
      );

    if (column.id === "status")
      return (
        <span
          style={{
            backgroundColor: "#2196F350",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "999px",
          }}
        >
          <Typography variant="tableContent"></Typography> {value}
        </span>
      );

    if (column.id === "hireDate")
      return (
        <Typography variant="tableContent">
          {dayjs(value).format("DD MMM YYYY")}
        </Typography>
      );

    if (column.id === "age")
      return (
        <Typography variant="tableContent">
          {dayjs().diff(row.birthday, "year") + " " + t("Years")}
        </Typography>
      );

    if (column.id === "vehicle")
      return (
        <Typography variant="tableContent">
          {Boolean(row.car) ? `${row.car.brand} ${row.car.model}` : ""}{" "}
        </Typography>
      );

    return <Typography variant="tableContent"> {value}</Typography>;
  };

  const handleChange = (e) => {
    if (e.target.name === "CNP") {
      setDriverData({
        ...driverData,
        [e.target.name]: e.target.value,
        ["birthday"]: getDOB(e.target.value),
      });
    } else {
      setDriverData({
        ...driverData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (!driverModal) return;

    setDriverData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      CNP: "",
      adress: "",
      birthday: Date.now(),
      carId: "",
    });
  }, [driverModal]);

  useEffect(() => {
    (async () => {
      const drivers = await getAllDrivers();
      const cars = await getCars();

      setDrivers(drivers);
      setCars(cars);
      setLoadingDrivers(false);
    })();
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography variant="pageTitle">{t("Drivers")}</Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Drivers")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTable
        title={t("Drivers")}
        showAdd={canAlterDrivers}
        onAdd={() => setDriverModal(true)}
        labels={labels}
        loading={loadingDrivers}
        // getterFunction={getAllDrivers}
        // setterFunction={setDrivers}
        tableData={drivers}
        cellModifier={cellModifier}
      />

      <CustomDialog
        handleClose={() => setDriverModal(false)}
        open={driverModal}
        buttonTitle={t("Add")}
        title={t("Add Driver")}
        button1={t("Finalize")}
        onClickButton1={() =>
          addDriver(driverData, () => {
            getAllDrivers().then((drivers) => {
              setDrivers(drivers);
              setDriverModal(false);
              toast.success(t("Driver has been added!"));
            });
          })
        }
        button2={t("Quit")}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            spacing={2}
            columns={{ xs: 4, md: 12 }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("First name")}
                value={driverData.firstName}
                name="firstName"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete="off"
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Last name")}
                value={driverData.lastName}
                name="lastName"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete="off"
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Phone Number")}
                name="phone"
                value={driverData.phone}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete="off"
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label="Email"
                name="email"
                value={driverData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                autoComplete="off"
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label="CNP"
                name="CNP"
                value={driverData.CNP}
                onChange={handleChange}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Birthday")}
                  name="Birthday"
                  value={getDOB(driverData.CNP)}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                        width: "16rem",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={t("Car")}
                name="carId"
                value={driverData.carId}
                onChange={handleChange}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                {cars.map((car) => (
                  <MenuItem key={car.id} value={car.id}>
                    {`${car.brand} ${car.model}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={t("Address")}
                name="adress"
                value={driverData.adress}
                onChange={handleChange}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </div>
  );
}

export default Drivers;
