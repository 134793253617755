import React, { useState } from "react";
import { CustomDialog } from "lib";
import { getDOB } from "utils/functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import {
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  MenuItem,
} from "@mui/material";

import { addClient, uploadClientDoc } from "api/API";

const AddClientModal = ({
  addModal,
  setAddModal = () => {},
  computingClients,
  triggerRefetch,
  setTriggerRefetch = () => {},
  countries,
  states,
  cities,
  getAndSetCities = () => {},
  getAndSetStates = () => {},
}) => {
  const { t } = useTranslation();

  const initialClientData = {
    countryId: 181,
    cityId: null,
    stateId: null,
    nationality: "ROMANA",
    firstName: "",
    lastName: "",
    CNP: "",
    series: "",
    Adress: "",
    Gender: "",
    ciExpireDate: "",
    birthday: "",
    ICNumber: "",
    email: "",
    CIP: "",
    phone: "",
    birthPlace: "",
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const [addClientData, setAddClientData] = useState(initialClientData);
  const [docPicture, setDocPicture] = useState(null);

  const [isDocScanned, setIsDocScanned] = useState(false);

  const handleChange = async (e) => {
    if (e.target.name === "CNP") {
      setAddClientData({
        ...addClientData,
        [e.target.name]: e.target.value,
        ["birthday"]: getDOB(e.target.value),
      });
    } else {
      setAddClientData({
        ...addClientData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "countryId") await getAndSetStates(e.target.value);
      if (e.target.name === "stateId")
        await getAndSetCities(addClientData.countryId, e.target.value);
    }
  };

  const getDataFromDeskoScanner = async () => {
    try {
      setIsDocScanned(true);
      const response = await fetch(
        `${process.env.REACT_APP_SCANNER_SIGNATURE_URL}/api/scan`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET",
            "Content-Type": "application/json",
          },
        }
      );

      const scannedData = await response.json();

      if (scannedData.scanState === "Scan completed") {
        autoCompleteForm(scannedData.fields);
        setDocPicture(scannedData.idPhoto);
      } else {
        throw new Error("Eroare");
      }
    } catch (error) {
      toast.error("Va rugam sa asezati buletinul pe scanner!");
    } finally {
      setIsDocScanned(false);
    }
  };

  const autoCompleteForm = (dataFromScanner) => {
    // const mappedKeys = {
    //   OCR_SecondaryName: "firstName",
    //   OCR_PrimaryName: "lastName",
    //   OCR_PersonalNumber: "CNP",
    //   OCR_DocumentNumber: "series",
    //   OCR_Address: "Adress",
    //   OCR_Sex: "Gender",
    //   GENERIC_ExpiryDateIso: "ciExpireDate",
    // };

    const mappedKeys = {
      MRZ_HolderNameSecondary: "firstName",
      MRZ_HolderNamePrimary: "lastName",
      OCR_PersonalNumber: "CNP",
      OCR_DocumentNumber: "series",
      OCR_Address: "Adress",
      OCR_Sex: "Gender",
      GENERIC_ExpiryDateIso: "ciExpireDate",
      OCR_BirthPlace: "birthPlace",
    };

    const newObject = initialClientData;
    //mapping through the data and if the key is in our mappedKeys object, then we insert the values in the new Object
    dataFromScanner.forEach((data) => {
      if (data.key in mappedKeys) newObject[mappedKeys[data.key]] = data.value;
    });

    newObject.birthday = getDOB(newObject.CNP);
    newObject.nationality = "ROMANA";

    //we need to split the series as its coming concatenated in the same variable from the scanner app
    const [series, number] = newObject.series.split(" ");
    newObject.series = series;
    newObject.ICNumber = number;
    newObject.Gender = newObject.Gender === "M" ? "MALE" : "FEMALE";

    setAddClientData(newObject);
  };

  const transformBase64imgToFormData = () => {
    // Create a Blob from base64 data
    const blob = new Blob(
      [Uint8Array.from(atob(docPicture), (c) => c.charCodeAt(0))],
      { type: "image/jpeg" }
    );

    // Create a FormData object
    const formData = new FormData();

    // Append the blob to the FormData object with the desired field name
    formData.append("name", "IC");
    formData.append("type", "IC");
    formData.append("file", blob, "image.jpg");

    return formData;
  };

  const handleCloseModal = () => {
    setAddClientData(initialClientData);
    setAddModal(false);
  };

  return (
    <CustomDialog
      handleClose={() => setAddModal(false)}
      open={addModal}
      title={t("Add client")}
      button1={t("Finalize")}
      button1Loading={isDocScanned}
      onClickButton1={() => {
        if (addClientData?.CNP < 13) {
          toast.error("Campul CNP este incorect");
          return;
        }

        addClient(addClientData).then((res) => {
          if (res.ok) {
            if (docPicture) {
              const x = transformBase64imgToFormData();
              uploadClientDoc(res.data.id, x);
            }
            setTriggerRefetch(!triggerRefetch);
            toast.success(`Clientul a fost adăugat!`);
            setAddClientData(initialClientData);
            setAddModal(false);
          }
        });
      }}
      button2={t("Quit")}
      onClickButton2={handleCloseModal}
      buttonScanDoc={"Scan doc"}
      buttonScanDocLoading={isDocScanned}
      onClickButtonScanDoc={getDataFromDeskoScanner}
    >
      <Box
        fullWidth
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          spacing={3}
          columns={{ xs: 4, md: 12 }}
        >
          <Grid item xs={12} md={6}>
            <div>
              <TextField
                fullWidth
                value={addClientData.firstName}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                required
                label={t("First name")}
                name="firstName"
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <TextField
                required
                value={addClientData.lastName}
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="lastName"
                label={t("Last name")}
                onChange={handleChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div>
              <TextField
                required
                value={addClientData.phone}
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                label={t("Phone Number")}
                name="phone"
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <TextField
                fullWidth
                value={addClientData.email}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                label="Email"
                name="email"
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <TextField
                required
                value={addClientData.CNP}
                inputProps={{ maxLength: 13 }}
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                label="CNP"
                name="CNP"
                onChange={handleChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Birthday")}
                  value={getDOB(addClientData.CNP)}
                  name="birthday"
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyDown={onKeyDown}
                      disabled={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      required
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <TextField
                required
                fullWidth
                value={addClientData.series}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="series"
                label={t("Series")}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <TextField
                required
                fullWidth
                value={addClientData.ICNumber}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="ICNumber"
                label={t("Number")}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={addClientData.CIP}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label="CIP"
              name="CIP"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              value={addClientData.contactPerson}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Contact Person")}
              name="contactPerson"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              value={addClientData.nationality}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Nationality")}
              name="nationality"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              select
              defaultValue={181}
              label={t("Country")}
              onChange={handleChange}
              name="countryId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              select
              label={t("State")}
              onChange={handleChange}
              // defaultValue={4720}
              name="stateId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {states.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              select
              label={t("Cities")}
              onChange={handleChange}
              name="cityId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {cities.map((city) => (
                <MenuItem key={city.id} value={city.id}>
                  {city.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Expiration date CI")}
                value={addClientData?.ciExpireDate}
                name="ciExpireDate"
                onChange={(value) => {
                  handleChange({ target: { name: "ciExpireDate", value } });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              value={addClientData.birthPlace}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Birth Place")}
              name="birthPlace"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              value={addClientData.Adress}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Address")}
              name="Adress"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ marginLeft: "10px" }}>
              <RadioGroup
                row
                name="Gender"
                value={addClientData.Gender}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="MALE"
                  control={<Radio />}
                  label={t("Male")}
                />
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio />}
                  label={t("Female")}
                />
                <FormControlLabel
                  value="Neutral"
                  control={<Radio />}
                  label={t("Neutral")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddClientModal;
