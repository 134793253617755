import React, { useState, useEffect } from "react";
import { CustomDialog } from "lib";
import { Box, Grid, MenuItem, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { addUser, getRoles } from "api/API";

const SignInModal = ({ open, setOpen, fetchUsers }) => {
  const { t } = useTranslation();
  const [signInData, setSignInData] = useState({});
  const [roles, setRoles] = useState([]);

  const handleChange = (e) => {
    setSignInData({
      ...signInData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getRoles().then((res) => setRoles(res));
  }, []);

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Create new account")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() =>
        addUser(signInData, () => {
          fetchUsers().then(() => {
            setSignInData({});
            setOpen(false);
            toast.success(t("The user has been successfully added!"));
          });
        })
      }
    >
      <Box
        sx={{ backgroundColor: "white", borderRadius: "1rem", height: "100%" }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          columns={{ xs: 4, md: 12 }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              name="name"
              label={t("Name")}
              onChange={handleChange}
              value={signInData.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              name="email"
              label={t("Email")}
              onChange={handleChange}
              value={signInData.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              name="roleId"
              value={signInData.roleId}
              label={t("Role")}
              onChange={handleChange}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              name="password"
              label={t("Password")}
              type="password"
              onChange={handleChange}
              value={signInData.password}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default SignInModal;
