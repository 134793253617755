import { useState, useEffect } from "react";
import { useUser } from "hooks/useUser";
import useWindowDimensions from "../hooks/useWindowDimensions";

import InsertChartIcon from "@mui/icons-material/InsertChart";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PlaceIcon from "@mui/icons-material/Place";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import GroupIcon from "@mui/icons-material/Group";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DescriptionIcon from "@mui/icons-material/Description";
import { Drawer } from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import { Appbar, Sidebar } from "../lib";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoutIcon } from "resources/svg/menu-navbar/LogoutIcon.svg";
import { ReactComponent as RomanaIcon } from "resources/svg/menu-navbar/RomanaIcon.svg";
import { ReactComponent as EnglishIcon } from "resources/svg/menu-navbar/EnglishIcon.svg";
import ConfirmLogoutModal from "lib/modals/ConfirmLogoutModal";

function Layout() {
  const { can } = useUser();
  const { vw } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const menuHandler = () => {
    setOpen(!open);
  };

  const handleLangChange = (prop) => {
    const lang = prop;
    localStorage.setItem("preferredLang", lang);
    setSelectedLang(lang);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    setSelectedLang(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "6rem",
          top: 0,
          left: 0,
          zIndex: 100,
        }}
      >
        <Appbar onMenuClick={menuHandler} />

        <Drawer
          open={open}
          onClose={menuHandler}
          variant={vw >= 1080 ? "permanent" : "temporary"}
          sx={{ "& .MuiPaper-root": { height: "auto" } }}
        >
          <div
            style={{
              position: "fixed",
              width: "16rem",
              height: vw >= 1080 ? "calc(100vh - 6rem)" : "100vh",
              top: vw >= 1080 ? "6rem" : 0,
              left: 0,
              backgroundColor: "#FFFFFF",
              zIndex: 50,
            }}
          >
            <Sidebar
              navMain={[
                {
                  render: true,
                  label: t("Administration"),
                  navItems: [
                    {
                      content: t("Dashboard"),
                      icon: <InsertChartIcon />,
                      render: true,
                      path: "/",
                      nested: false,
                    },

                    {
                      content: t("Tasks"),
                      icon: <AssignmentTurnedInIcon />,
                      render: can(0),
                      path: "/tasks",
                      nested: false,
                    },

                    {
                      content: t("Clients"),
                      icon: <PeopleAltIcon />,
                      render: can(4),
                      path: "/clients",
                      nested: true,
                      nestedItems: [
                        {
                          content: t("Visualize clients"),
                          render: can(4),
                          path: "/clients/visualize-clients",
                        },
                        {
                          content: t("Tax returns"),
                          render: can(5),
                          path: "/clients/tax-returns",
                        },
                        {
                          content: t("Departures list"),
                          render: can(5),
                          path: "/clients/departures-list",
                        },
                        {
                          content: t("Arrivals list"),
                          render: can(5),
                          path: "/clients/arrivals-list",
                        },
                        {
                          content: t("Registrations"),
                          render: can(5),
                          path: "/clients/registrations",
                        },
                      ],
                    },

                    {
                      content: t("Trips"),
                      icon: <AirportShuttleIcon />,
                      render: can(6),
                      path: "/trips",
                      nested: true,
                      nestedItems: [
                        {
                          content: t("All trips"),
                          render: true,
                          path: "/trips/all-trips",
                        },
                        {
                          content: t("Trips (departure)"),
                          render: true,
                          path: "/trips/departure-trips",
                        },
                        {
                          content: t("Trips (arrival)"),
                          render: true,
                          path: "/trips/arrival-trips",
                        },
                      ],
                    },
                    {
                      content: t("Registru Intrari Iesiri"),
                      icon: <DescriptionIcon />,
                      render: can(25),
                      path: "/inoutreport",
                    },
                    {
                      content: t("Documents"),
                      icon: <SummarizeIcon />,
                      render: can(29),
                      path: "/documents",
                    },
                  ],
                },

                {
                  render: true,
                  label: t("Nomenclators"),
                  navItems: [
                    {
                      content: t("Fleet"),
                      icon: <DirectionsCarIcon />,
                      render: can(8),
                      path: "/fleet",
                    },
                    {
                      content: t("Drivers"),
                      icon: <AccountBoxIcon />,
                      render: can(10),
                      path: "/drivers",
                    },
                    {
                      content: t("Destinations"),
                      icon: <PlaceIcon />,
                      render: can(18),
                      path: "/destinations",
                    },
                    {
                      content: t("Tags"),
                      icon: <StyleIcon />,
                      render: can(14),
                      path: "/tags",
                    },
                    {
                      content: t("Fields"),
                      icon: <AgricultureIcon />,
                      render: can(16),
                      path: "/fields",
                    },
                    {
                      content: t("Users"),
                      icon: <GroupIcon />,
                      render: can(20),
                      path: "/users",
                    },
                    {
                      content: t("Periods"),
                      icon: <AccessTimeIcon />,
                      render: can(27),
                      path: "/periods",
                    },
                    {
                      content: t("Reports"),
                      icon: <SummarizeIcon />,
                      render: can(23),
                      path: "/reports",
                    },
                  ],
                },
                {
                  render: true,
                  label: t("Account"),
                  navItems: [
                    {
                      content: t("Logout"),
                      icon: <LogoutIcon />,
                      render: true,
                      nested: false,
                      onClick: () => setOpenLogoutModal(true),
                    },
                    {
                      withNoIconColor: true,
                      content: selectedLang === "ro" ? "Română" : "English",
                      icon:
                        selectedLang === "ro" ? (
                          <RomanaIcon />
                        ) : (
                          <EnglishIcon />
                        ),
                      render: true,
                      nested: true,
                      nestedItems: [
                        {
                          content: "Română",
                          render: true,
                          icon: <RomanaIcon />,
                          onClick: () => {
                            handleLangChange("ro");
                          },
                        },
                        {
                          content: "English",
                          render: true,
                          icon: <EnglishIcon />,
                          onClick: () => {
                            handleLangChange("en");
                          },
                        },
                      ],
                    },
                  ],
                },
              ]}
            />
          </div>
        </Drawer>
      </div>
      <ConfirmLogoutModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
    </>
  );
}

export default Layout;
