import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomDialog, CustomTable } from "lib";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import dayjs from "dayjs";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUser } from "hooks/useUser";

import {
  Typography,
  Breadcrumbs,
  Link,
  Box,
  TextField,
  Grid,
} from "@mui/material";

import { toast } from "react-toastify";

import { addFleet, getAllFleets, deleteFleet } from "api/API";

function Fleet() {
  const navigate = useNavigate();
  const [fleetModal, setFleetModal] = useState(false);
  const [fleetData, setFleetData] = useState({});
  const [fleet, setFleet] = useState([]);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { can } = useUser();

  const [loadingFleet, setLoadingFleet] = useState(true);

  const canViewFleets = can(8);
  const canAlterFleets = can(9);

  const labels = [
    {
      id: "brand",
      label: t("Brand"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "model",
      label: t("Model"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "registrationPlate",
      label: t("Register plate"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "fabricationYear",
      label: t("Year"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "insurance",
      label: t("Insurance"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "casco",
      label: "Casco",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "itp",
      label: "ITP",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "rovineta",
      label: t("Vignette"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "rovinetaRo",
      label: t("Vignette (RO)"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions")
      return (
        <>
          <span
            onClick={() => navigate(`./vehicle/` + row.id)}
            style={{ cursor: "pointer" }}
          >
            <ArrowForwardIcon color="primary" />
          </span>

          {canAlterFleets && (
            <>
              &nbsp; &nbsp; &nbsp;
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  confirm(
                    t("Are you sure you want to delete this driver?"),
                    () =>
                      deleteFleet(row.id, () =>
                        getAllFleets().then((fleet) => {
                          setFleet(fleet);
                          toast.success(t("Vehicle has been deleted!"));
                        })
                      )
                  )
                }
              >
                <DeleteIcon color="errorCustom" />
              </span>
            </>
          )}
        </>
      );

    if (column.id === "status")
      return (
        <span
          style={{
            backgroundColor: "#2196F350",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "999px",
          }}
        >
          <Typography variant="tableContent"> {value}</Typography>
        </span>
      );

    if (
      ["insurance", "itp", "casco", "rovineta", "rovinetaRo"].indexOf(
        column.id
      ) > -1
    )
      return (
        <Typography variant="tableContent">
          {dayjs(value).format("DD MMM YYYY")}
        </Typography>
      );

    return <Typography variant="tableContent">{value}</Typography>;
  };

  useEffect(() => {
    (async () => {
      const fleee = await getAllFleets();
      setFleet(fleee);
      setLoadingFleet(false);
    })();
  }, []);

  const handleChange = (e) => {
    setFleetData({
      ...fleetData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!fleetModal) return;

    setFleetData({
      brand: "",
      model: "",
      registrationPlate: "",
      fabricationYear: "",
      kilometres: "",
      insurance: new Date(),
      casco: new Date(),
      itp: new Date(),
    });
  }, [fleetModal]);

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography variant="pageTitle">{t("Fleet")}</Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Fleet")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTable
        title={t("Vehicle list")}
        showAdd={canAlterFleets}
        onAdd={() => setFleetModal(true)}
        labels={labels}
        tableData={fleet}
        cellModifier={cellModifier}
        loading={loadingFleet}
      />

      <CustomDialog
        handleClose={() => setFleetModal(false)}
        open={fleetModal}
        title={t("Add fleet")}
        button1={t("Finalize")}
        button2={t("Quit")}
        onClickButton1={() => {
          const newFleetData = { ...fleetData };
          newFleetData.fabricationYear = parseInt(newFleetData.fabricationYear);
          addFleet(newFleetData, () => {
            getAllFleets().then((res) => {
              setFleet(res);
              setFleetModal(false);
              toast.success(t("Vehicle has been added!"));
            });
          });
        }}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            spacing={2}
            columns={{ xs: 4, md: 12 }}
          >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Brand")}
                name="brand"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label="Model"
                name="model"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Register plate")}
                name="registrationPlate"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                inputProps={{
                  type: "number",
                }}
                id="outlined-required"
                label={t("Year")}
                name="fabricationYear"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                inputProps={{
                  type: "number",
                }}
                id="outlined-required"
                label={t("Kilometers")}
                name="kilometres"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Insurance")}
                  name="insurance"
                  value={fleetData.insurance}
                  onChange={(value) => {
                    handleChange({ target: { name: "insurance", value } });
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                        width: "16rem",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Casco"
                  name="casco"
                  value={fleetData.casco}
                  onChange={(value) => {
                    handleChange({ target: { name: "casco", value } });
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                        width: "16rem",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="ITP"
                  name="itp"
                  value={fleetData.itp}
                  onChange={(value) => {
                    handleChange({ target: { name: "itp", value } });
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                        width: "16rem",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </div>
  );
}

export default Fleet;
